import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Divider, List, message, Popconfirm } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import UpdatePartner from '../UpdatePartner';
import NewPartner from '../NewPartner';
import { useArea } from '../../../context/AreaContext';
import api from '../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';

// import { Container } from './styles';

const PartnersList: React.FC = () => {
  const [newPartnerVisible, setNewPartnerVisible] = useState<boolean>(false);
  const [updatePartnerVisible, setUpdatePartnerVisible] = useState<boolean>(
    false,
  );

  const { area } = useArea();
  const [partners, setPartners] = useState<Array<any>>([]);
  const [partner, setPartner] = useState<any>();

  useEffect(() => {
    getPartners();
  }, [area]);

  const getPartners = async () => {
    try {
      const { data } = await api.get(`/${area.endpoint}/partners`);
      setPartners(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewPartner = () => {
    setNewPartnerVisible(!newPartnerVisible);
  };

  const handleUpdatePartner = (item: any) => {
    setPartner(item);
    setUpdatePartnerVisible(!updatePartnerVisible);
  };

  const handleDelete = async (item: any) => {
    try {
      await api.delete(`/${area.endpoint}/partner/${item.id}`);
      message.success(basicSuccessFeedback);
      getPartners();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const partnerTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  return (
    <>
      <Button type="primary" onClick={handleNewPartner}>
        Cadastrar Parceiro
      </Button>
      <Divider />
      <List
        dataSource={partners}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta title={partnerTitle(item.name)} />
            <div>
              <Button
                type="link"
                onClick={() => {
                  handleUpdatePartner(item);
                }}
              >
                <EditFilled />
              </Button>
            </div>
            <div>
              <Popconfirm
                title="Deseja excluir?"
                onConfirm={() => {
                  handleDelete(item);
                }}
                okText="Excluir"
                cancelText="Cancelar"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </List.Item>
        )}
      />

      {newPartnerVisible && (
        <NewPartner
          visible={newPartnerVisible}
          setVisible={setNewPartnerVisible}
          getPartners={getPartners}
        />
      )}

      {updatePartnerVisible && (
        <UpdatePartner
          visible={updatePartnerVisible}
          setVisible={setUpdatePartnerVisible}
          partner={partner}
          getPartners={getPartners}
        />
      )}
    </>
  );
};

export default PartnersList;
