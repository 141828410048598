import React from 'react';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UserSwitchOutlined,
  ToolOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Logo, LogoBox } from './styles';
import logo from '../../assets/logo-white.png';
import { useSession } from '../../context/SessionContext';

const { Sider } = Layout;

interface IProps {
  collapsed: boolean;
  onCollapse: () => void;
}

const SideMenu: React.FC<IProps> = ({ collapsed, onCollapse }) => {
  const { logout } = useSession();
  const handleCollapse = () => {
    onCollapse();
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Sider
      breakpoint="md"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
    >
      <Menu theme="dark" mode="inline">
        <LogoBox>
          <Link to="/">
            <Logo src={logo} />
          </Link>
        </LogoBox>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/">Incício</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<UserSwitchOutlined />}>
          <Link to="/usuarios">Usuários</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<ToolOutlined />}>
          <Link to="/configuracoes">Configurações</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<LogoutOutlined />} onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideMenu;
