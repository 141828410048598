import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Divider, List, message, Popconfirm, Tag } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import UpdatePartner from '../UpdateSeller';
import NewSeller from '../NewSeller';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';
import api from '../../../services/api';

const SellersList: React.FC = () => {
  const [newSellerVisible, setNewSellerVisible] = useState<boolean>(false);
  const [sellers, setSellers] = useState<any[]>([]);
  const [seller, setSeller] = useState<any>();
  const [updateSellerVisible, setUpdateSellerVisible] = useState<boolean>(
    false,
  );

  useEffect(() => {
    getSellers();
  }, []);

  const getSellers = async () => {
    try {
      const { data } = await api.get('/sellers');
      setSellers(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewSeller = () => {
    setNewSellerVisible(!newSellerVisible);
  };

  const handleUpdateSeller = (item: any) => {
    setUpdateSellerVisible(!updateSellerVisible);
    setSeller(item);
  };

  const handleDelete = async (id: any) => {
    try {
      await api.delete(`/seller/${id}`);
      getSellers();
      message.success(basicSuccessFeedback);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const sellerTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  return (
    <>
      <Button type="primary" onClick={handleNewSeller}>
        Cadastrar Vendedor
      </Button>
      <Divider />
      <List
        dataSource={sellers}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta title={sellerTitle(item.name)} />
            <div>
              {item.states.map((state: any) => (
                <Tag color="blue" key={state.id}>
                  {state?.state.split(' ')[0]}
                </Tag>
              ))}
            </div>
            <div>
              <Button
                type="link"
                onClick={() => {
                  handleUpdateSeller(item);
                }}
              >
                <EditFilled />
              </Button>
            </div>
            <div>
              <Popconfirm
                title="Deseja excluir?"
                onConfirm={() => {
                  handleDelete(item.id);
                }}
                okText="Excluir"
                cancelText="Cancelar"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </List.Item>
        )}
      />
      {newSellerVisible && (
        <NewSeller
          getSellers={getSellers}
          visible={newSellerVisible}
          setVisible={setNewSellerVisible}
        />
      )}

      {updateSellerVisible && (
        <UpdatePartner
          visible={updateSellerVisible}
          setVisible={setUpdateSellerVisible}
          seller={seller}
          getSellers={getSellers}
        />
      )}
    </>
  );
};

export default SellersList;
