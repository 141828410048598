import { Card, Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import TestimonialsList from './TestimonialsList';

// import { Container } from './styles';

const Testimonials: React.FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Card>
          <Title level={5}>Depoimentos</Title>
          <Divider />
          <TestimonialsList />
        </Card>
      </Col>
    </Row>
  );
};

export default Testimonials;
