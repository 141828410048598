import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Skeleton,
  Space,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

// import { Container } from './styles';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getProducts: () => void;
  productPath: any;
  subCategoryId: any;
}

const UpdateProduct: React.FC<IProps> = ({
  visible,
  setVisible,
  getProducts,
  productPath,
  subCategoryId,
}) => {
  const { area } = useArea();
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>();
  const [newCover, setNewCover] = useState<boolean>(false);
  const [cover, setCover] = useState<Array<any>>([]);
  const [newTechnicalData, setNewTechnicalData] = useState<boolean>(false);
  const [technicalData, setTechnicalData] = useState<Array<any>>([]);
  const [images, setImages] = useState<Array<any>>([]);

  useEffect(() => {
    if (productPath) getProduct();
  }, [productPath]);

  useEffect(() => {
    if (product) {
      setNewCover(false);
      setCover([
        {
          uid: '1',
          name: product?.spotlightimage,
          status: 'done',
          url: product?.spotlightimage,
          size: 200,
          type: '',
        },
      ]);

      if (product?.technicalData) {
        setNewTechnicalData(false);
        setTechnicalData([
          {
            uid: '1',
            name: product?.technicalData,
            status: 'done',
            url: product?.technicalData,
            size: 200,
            type: '',
          },
        ]);
      }

      const aux: any[] = [];
      product.images.map((item: any) =>
        aux.push({
          uid: item.path,
          name: item?.path,
          status: 'done',
          url: item?.path,
          size: 200,
          type: '',
          oldImage: true,
        }),
      );

      setImages(aux);
    }
  }, [product]);

  const getProduct = async () => {
    try {
      const { data } = await api.get(`${area.endpoint}${productPath}`);
      setProduct(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const toggleVisible = () => {
    setProduct(null);
    setVisible(false);
  };

  const handleCover = (e: any) => {
    setCover([e]);
    setNewCover(true);
    return false;
  };

  const handleRemoveCover = () => {
    setCover([]);
  };

  const handleTechnicalData = (e: any) => {
    setTechnicalData([e]);
    setNewTechnicalData(true);
    return false;
  };

  const handleRemoveTechnicalData = () => {
    setTechnicalData([]);
  };

  const handleImages = (e: any) => {
    setImages([...images, e]);
    return false;
  };

  const handleRemoveImages = (e: any) => {
    setImages(images.filter(item => item.name !== e.name));
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const { name, description, videos, articles } = data;
    try {
      let spotlightImage;
      if (newCover) {
        spotlightImage = await fileUpload(cover[0], 'image', '/image');
      } else {
        const aux = product.spotlightimage.split('/');
        spotlightImage = aux[aux.length - 1];
      }

      let technicalDataFile;
      if (newTechnicalData) {
        technicalDataFile = await fileUpload(technicalData[0], 'file', '/file');
      } else {
        const aux = product?.technicalDataFile?.split('/');
        technicalDataFile = aux?.[aux?.length - 1];
      }

      const newImages: any = [];
      const oldImages: any = [];

      images.map(item => {
        if (item.oldImage) {
          const aux = item.name.split('/');
          oldImages.push(aux[aux.length - 1]);
        } else {
          newImages.push(item);
        }
        return false;
      });

      const newImagesPaths: any = [];

      const promise = newImages.map(async (item: any) =>
        newImagesPaths.push(await fileUpload(item, 'image', '/image')),
      );

      await Promise.all(promise);
      const imagesPaths = [...oldImages, ...newImagesPaths];

      await api.put(`/${area.endpoint}/product`, {
        id: product.id,
        subcategoryId: subCategoryId,
        name,
        description,
        spotlightImage,
        images: imagesPaths,
        technicalData: technicalDataFile,
        videos,
        articles,
      });
      setVisible(false);

      setLoading(false);
      getProducts();
    } catch (err) {
      console.log({ err });
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      footer={null}
      visible={visible}
      title="Editar Produto"
      onCancel={toggleVisible}
    >
      {product && (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Nome do Produto"
            name="name"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o nome do produto!',
              },
            ]}
            initialValue={product?.name}
          >
            <Input
              placeholder="Informe o nome do produto"
              value={product?.name}
            />
          </Form.Item>
          <Form.Item
            label="Descrição do Produto"
            name="description"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o nome do produto!',
              },
            ]}
            initialValue={product?.description}
          >
            <TextArea
              placeholder="Informe a descrição do produto"
              value={product?.description}
            />
          </Form.Item>
          <Form.Item
            name="cover"
            label="Imagem de Capa do Produto"
            rules={[
              () => ({
                validator() {
                  if (cover.length === 0) {
                    return Promise.reject(
                      new Error('Por favor, faça o upload do arquivo!'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Upload
              accept=".jpg, .jpeg, .png, .webp"
              beforeUpload={handleCover}
              onRemove={handleRemoveCover}
              fileList={cover}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Imagens do Produto (máx. 10)" name="images">
            <Upload
              accept=".jpg, .jpeg, .png, .webp"
              beforeUpload={handleImages}
              onRemove={handleRemoveImages}
              fileList={images}
              maxCount={10}
            >
              <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="technicalData"
            label="Ficha Técnica"
            rules={[
              () => ({
                validator() {
                  if (technicalData.length === 0) {
                    return Promise.resolve();
                  }
                  if (technicalData[0]?.size > 100 * 1024 * 1024) {
                    return Promise.reject(
                      new Error('Por favor, tamanho máximo 100MB!'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Upload
              accept=".pdf"
              beforeUpload={handleTechnicalData}
              onRemove={handleRemoveTechnicalData}
              fileList={technicalData}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
            </Upload>
          </Form.Item>

          <Paragraph>Vídeos</Paragraph>
          <Form.List name="videos" initialValue={product?.videos}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      rules={[{ required: true, message: 'Informe o Nome' }]}
                    >
                      <Input placeholder="Título" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'link']}
                      rules={[{ required: true, message: 'Informe o Link' }]}
                    >
                      <Input placeholder="Link" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Vídeo
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Paragraph>Artigos</Paragraph>
          <Form.List name="articles" initialValue={product?.articles}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      rules={[{ required: true, message: 'Informe o Nome' }]}
                    >
                      <Input placeholder="Título" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'link']}
                      rules={[{ required: true, message: 'Informe o Link' }]}
                    >
                      <Input placeholder="Link" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Artigo
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button loading={loading} type="primary" block htmlType="submit">
              Salvar
            </Button>
          </Form.Item>
        </Form>
      )}

      {!product && <Skeleton active />}
    </Modal>
  );
};

export default UpdateProduct;
