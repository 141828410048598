import { Form, Typography, Input, Button, Divider, message } from 'antd';
import React, { useState } from 'react';
import api from '../../services/api';
import { negativeFeedback } from '../../utils/feedback';

const { Title } = Typography;

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { email } = values;
    try {
      await api.post('/user/password/recovery', {
        username: email,
      });

      message.success('Email de recuperação enviado com sucesso!');
      setLoading(false);
    } catch (err) {
      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <>
      <Title level={5} style={{ textAlign: 'center' }}>
        Esqueci Minha Senha
      </Title>
      <Divider />
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Por favor, informe seu email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="link" href="/login">
            Voltar
          </Button>
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Recuperar Senha
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotPassword;
