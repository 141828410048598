import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Button, Input, Divider, message, Popconfirm } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Container } from './styles';
import api from '../../services/api';
import { useArea } from '../../context/AreaContext';
import { negativeFeedback, basicSuccessFeedback } from '../../utils/feedback';

const { Search } = Input;

interface IProps {
  type: 'products' | 'videos' | 'articles';
  categories: Array<any>;
  onUpdate?: () => void;
}

const types = {
  products: 'produtos',
  videos: 'videos',
  articles: 'artigos',
};

const CategoriesList: React.FC<IProps> = ({ type, categories, onUpdate }) => {
  const { area } = useArea();

  const handleAdd = async (name: string) => {
    if (!name) {
      return;
    }

    try {
      await api.post(`/${area.endpoint}/category`, {
        name,
        type,
      });
      message.success(basicSuccessFeedback);
      if (onUpdate) onUpdate();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/${area.endpoint}/category/${id}`);
      message.success(basicSuccessFeedback);
      if (onUpdate) onUpdate();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleUpdate = async (name: string, id: string) => {
    try {
      await api.put(`/${area.endpoint}/category`, {
        id,
        name,
      });
      message.success(basicSuccessFeedback);
      if (onUpdate) onUpdate();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const categoryTitle = (name: string, id: string) => (
    <Paragraph
      style={{ marginBottom: 0 }}
      editable={{
        onChange: (newName: string) => {
          handleUpdate(newName, id);
        },
      }}
    >
      {name}
    </Paragraph>
  );

  return (
    <>
      <Search
        placeholder="Adicionar Categoria"
        allowClear
        enterButton={<PlusOutlined />}
        onSearch={handleAdd}
      />
      <Divider />
      <Container>
        <List
          dataSource={categories}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta title={categoryTitle(item.name, item.id)} />
              <div>
                <Popconfirm
                  title={`Excluir categoria e todos os ${types[type]} vinculados à ela?`}
                  onConfirm={() => {
                    handleDelete(item.id);
                  }}
                  okText="Excluir"
                  cancelText="Cancelar"
                >
                  <Button type="link" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>
            </List.Item>
          )}
        />
      </Container>
    </>
  );
};

export default CategoriesList;
