import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Upload, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getArticles: (path: string) => void;
  categoryPath?: string;
  article?: any;
}

const UpdateArticle: React.FC<IProps> = ({
  visible,
  setVisible,
  getArticles,
  categoryPath,
  article,
}) => {
  const handleVisible = () => {
    setVisible(false);
  };
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [newFile, setNewFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();

  useEffect(() => {
    if (article) {
      setNewFile(false);
      setFileList([
        {
          uid: '1',
          name: article?.document,
          status: 'done',
          url: article?.document,
          size: 200,
          type: '',
        },
      ]);
    }
  }, [article]);

  const handleUpload = (e: any) => {
    setFileList([e]);
    setNewFile(true);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const { title } = data;

    let document;

    if (newFile) {
      document = await fileUpload(fileList[0], 'file', '/file');
    } else {
      const aux = article.document.split('/');
      document = aux[aux.length - 1];
    }

    try {
      await api.put(`/${area.endpoint}/article`, {
        id: article?.id,
        title,
        document,
      });
      setVisible(false);
      setLoading(false);
      handleRemove();
      if (categoryPath) getArticles(categoryPath);
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Editar Artigo"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Título do Artigo"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o título do artigo!',
            },
          ]}
          initialValue={article?.title}
        >
          <Input
            placeholder="Informe o título do artigo"
            value={article?.title}
          />
        </Form.Item>
        <Form.Item
          label="Arquivo PDF (máx. 100MB)"
          name="document"
          rules={[
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".pdf"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit" block>
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateArticle;
