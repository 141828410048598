import { Button, Card, Divider, Form, Input, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import api from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';
import { passwordValidation } from '../../../utils/formValidations';

// import { Container } from './styles';

interface IProps {
  getUsers: () => void;
}

const NewUser: React.FC<IProps> = ({ getUsers }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { name, email: username, password } = data;
      await api.post('/user', { name, username, password });
      form.resetFields();
      getUsers();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Card>
      <Title level={5}>Novo Usuário</Title>
      <Divider />
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Nome do Usuário"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do usuário!',
            },
          ]}
        >
          <Input placeholder="Informe o nome do usuário" />
        </Form.Item>
        <Form.Item
          label="Email do Usuário"
          name="email"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o email do usuário!',
            },
          ]}
        >
          <Input placeholder="Informe o email do usuário" />
        </Form.Item>
        <Form.Item
          label="Senha do Usuário"
          name="password"
          rules={[
            { required: true, message: 'Por favor, informe a senha!' },
            () => ({
              validator(_, value) {
                const { valid, error } = passwordValidation(value);
                if (value && !valid) {
                  return Promise.reject(new Error(error));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Informe a senha do usuário" />
        </Form.Item>
        <Form.Item
          label="Confirmar Senha"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: 'Por favor, confirme a senha!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && getFieldValue('password') !== value) {
                  return Promise.reject(new Error(`As senhas não conferem!`));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirme a senha do usuário" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default NewUser;
