import { Form, Typography, Input, Button, Divider, message } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { negativeFeedback } from '../../utils/feedback';
import { passwordValidation } from '../../utils/formValidations';

const { Title } = Typography;

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const params: any = useParams();
  const { token } = params;

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { password } = values;
    if (!token) {
      message.error('Token de recuperação de senha não informado!');
      return;
    }

    try {
      await api.post(
        '/user/password/reset',
        {
          password,
        },
        {
          headers: { token },
        },
      );

      message.success('Senha alterada com sucesso!');
      history.push('/');
      setLoading(false);
    } catch (err) {
      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <>
      <Title level={5} style={{ textAlign: 'center' }}>
        Nova Senha
      </Title>
      <Divider />
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          label="Nova Senha"
          name="password"
          rules={[
            { required: true, message: 'Por favor, informe sua senha!' },
            () => ({
              validator(_, value) {
                const { valid, error } = passwordValidation(value);
                if (value && !valid) {
                  return Promise.reject(new Error(error));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirmar Senha"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: 'Por favor, confirme sua senha!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && getFieldValue('password') !== value) {
                  return Promise.reject(new Error(`As senhas não conferem!`));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Atualizar Senha
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ResetPassword;
