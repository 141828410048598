import axios from 'axios';

export const apiUrl = 'https://api.vertek.com.br/';

const api = axios.create({
  baseURL: apiUrl,
});

const getCookie = (cookieName: string) => {
  return document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
};

export const fileUpload = async (
  file: File,
  fileName: string,
  url: string,
): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append(fileName, file);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const { data } = await api.post(url, formData, { headers });
    const { path } = data;
    return path;
  } catch (err) {
    throw new Error('Upload File Error');
  }
};

export default api;
