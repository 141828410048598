import React from 'react';
import { List, Button, Typography, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Container } from './styles';
import api from '../../../services/api';
import { useArea } from '../../../context/AreaContext';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';

const { Paragraph } = Typography;

interface IProps {
  products: any[];
  getHighlightProducts: () => void;
}

const HighlightProducts: React.FC<IProps> = ({
  products,
  getHighlightProducts,
}) => {
  const { area } = useArea();
  const productTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  const handleRemoveHighlight = async (id: any) => {
    try {
      await api.delete(`/${area.endpoint}/product/spotlight/${id}`);
      message.success(basicSuccessFeedback);
      getHighlightProducts();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  return (
    <Container>
      <List
        dataSource={products}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta title={productTitle(item.name)} />
            <div>
              <Button
                type="link"
                danger
                onClick={() => {
                  handleRemoveHighlight(item.id);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </List.Item>
        )}
      />
    </Container>
  );
};

export default HighlightProducts;
