import React, { useState } from 'react';
import { Layout } from 'antd';

import { Wrapper } from './styles';
import SideMenu from '../../../components/SideMenu';
import AreaBar from '../../../components/AreaBar';

const { Content } = Layout;

interface IProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<IProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout>
      <SideMenu collapsed={collapsed} onCollapse={onCollapse} />
      <Layout
        style={{
          marginLeft: `${collapsed ? '80px' : '200px'}`,
          transition: '0.2s',
        }}
      >
        <Content>
          <Wrapper>
            <AreaBar />
            {children}
          </Wrapper>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
