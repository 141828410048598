import {
  Button,
  Divider,
  Form,
  List,
  Select,
  Typography,
  Popconfirm,
} from 'antd';
import React from 'react';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';

const { Option } = Select;
const { Paragraph } = Typography;

interface IProps {
  title: string;
  setCategory?: (payload: any) => void;
  handleNew?: () => void;
  handleUpdate: (item: any) => void;
  handleDelete: (item: any) => void;
  handleCategoryItems: (path: string) => void;
  categories: Array<any>;
  category?: any;
  items?: Array<any>;
}

const CategoryItems: React.FC<IProps> = ({
  title,
  handleNew,
  handleUpdate,
  handleDelete,
  setCategory,
  handleCategoryItems,
  categories = [],
  category,
  items = [],
}) => {
  const handleCategory = async (categoryPath: string) => {
    const categoryItem = categories.find(item => item.path === categoryPath);
    if (setCategory) setCategory(categoryItem);

    handleCategoryItems(categoryPath);
  };

  const itemTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  const handleNewItem = () => {
    if (handleNew) handleNew();
  };

  const handleUpdateItem = (item: any) => {
    handleUpdate(item);
  };

  const handleDeleteItem = (item: any) => {
    handleDelete(item);
  };

  return (
    <>
      <Form style={{ display: 'flex' }} layout="vertical">
        <Form.Item label="Selecionar Categoria">
          <Select
            style={{ width: 200, marginRight: 15 }}
            onChange={handleCategory}
          >
            {categories.map(item => (
              <Option key={item.id} value={item.path}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label=" ">
          <Button
            disabled={!category}
            onClick={handleNewItem}
            htmlType="button"
            type="primary"
          >
            {`Cadastrar ${title}`}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <List
        dataSource={items}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta title={itemTitle(item.title)} />
            <div>
              <Button
                type="link"
                onClick={() => {
                  handleUpdateItem(item);
                }}
              >
                <EditFilled />
              </Button>
            </div>
            <div>
              <Popconfirm
                title="Deseja excluir?"
                onConfirm={() => {
                  handleDeleteItem(item);
                }}
                okText="Excluir"
                cancelText="Cancelar"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

export default CategoryItems;
