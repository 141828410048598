import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getTestimonials: () => void;
}

const NewTestmony: React.FC<IProps> = ({
  visible,
  setVisible,
  getTestimonials,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const { area } = useArea();

  const handleVisible = () => {
    setVisible(false);
    handleRemove();
  };

  const handleUpload = (e: any) => {
    setFileList([e]);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { professionalName, professionalTitle, text } = data;
      const image = await fileUpload(fileList[0], 'image', '/image');
      await api.post(`/${area.endpoint}/reference`, {
        professionalName,
        professionalTitle,
        text,
        image,
      });
      setVisible(false);
      setLoading(false);
      handleRemove();
      getTestimonials();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Novo Depoimento"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Foto do(a) Profissional"
          name="image"
          rules={[
            {
              required: true,
              message: 'Por favor, faça o upload do arquivo!',
            },
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Nome do(a) Profissional"
          name="professionalName"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do(a) profissional!',
            },
          ]}
        >
          <Input placeholder="Informe o nome do(a) profissional" />
        </Form.Item>
        <Form.Item
          label="Especialidade do(a) Profissional"
          name="professionalTitle"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a especialidade do(a) profissional!',
            },
          ]}
        >
          <Input placeholder="Informe a especialidade do(a) profissional" />
        </Form.Item>
        <Form.Item
          label="Depoimento do(a) Profissional"
          name="text"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o depoimento do(a) profissional!',
            },
          ]}
        >
          <TextArea placeholder="Informe o depoimento do(a) profissional" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewTestmony;
