import styled from 'styled-components';

export const LogoBox = styled.div`
  padding: 5px;
`;

export const Logo = styled.img`
  width: 100%;
  transition: 0.2s;
`;
