import { Card, Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import PastEventsList from './PastEvents/PastEventsList';
import FutureEventsList from './FutureEvents/FutureEventsList/index';

const Events: React.FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24} lg={12}>
        <Card>
          <Title level={5}>Eventos Passados</Title>
          <Divider />
          <PastEventsList />
        </Card>
      </Col>
      <Col span={24} lg={12}>
        <Card>
          <Title level={5}>Próximos Eventos</Title>
          <Divider />
          <FutureEventsList />
        </Card>
      </Col>
    </Row>
  );
};

export default Events;
