import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { apiUrl, fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  banner: any;
  setVisible: (value: boolean) => void;
  getBanners: () => void;
}

const UpdateBanner: React.FC<IProps> = ({
  visible,
  setVisible,
  banner,
  getBanners,
}) => {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [newFile, setNewFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();

  useEffect(() => {
    if (banner) {
      setNewFile(false);
      setFileList([
        {
          uid: '1',
          name: banner?.image,
          status: 'done',
          url: banner?.image,
          size: 200,
          type: '',
        },
      ]);
    }
  }, [banner]);

  const handleVisible = () => {
    setVisible(false);
  };

  const handleUpload = (e: any) => {
    setFileList([e]);
    setNewFile(true);
    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const { title, description, buttonLabel, buttonLink } = data;

    let image;

    if (newFile) {
      image = await fileUpload(fileList[0], 'image', '/image');
    } else {
      const aux = banner.image.split('/');
      image = aux[aux.length - 1];
    }

    try {
      await api.put(`/${area.endpoint}/banner`, {
        id: banner?.id,
        title,
        description,
        buttonLabel,
        buttonLink,
        image,
      });
      setVisible(false);
      setLoading(false);
      handleRemove();
      getBanners();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Editar Banner"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Imagem de Fundo"
          name="image"
          required
          rules={[
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                if (fileList[0].size > 512 * 1024) {
                  return Promise.reject(
                    new Error('Por favor, tamanho máximo 512kB!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .gif, .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Título do Banner"
          name="title"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor, informe o título do banner!',
          //   },
          // ]}
          initialValue={banner?.title}
        >
          <Input
            placeholder="Informe o título do banner"
            value={banner?.title}
          />
        </Form.Item>
        <Form.Item
          label="Descrição do Banner"
          name="description"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor, informe a descrição do banner!',
          //   },
          // ]}
          initialValue={banner?.description}
        >
          <Input
            placeholder="Informe a descrição do banner"
            value={banner?.description}
          />
        </Form.Item>
        <Form.Item
          label="Texto do Botão"
          name="buttonLabel"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o texto do botão!',
            },
          ]}
          initialValue={banner?.buttonLabel}
        >
          <Input
            placeholder="Informe o texto do botão do banner"
            value={banner?.buttonLabel}
          />
        </Form.Item>
        <Form.Item
          label="Link de Redirecionamento do Botão"
          name="buttonLink"
          rules={[
            {
              required: true,
              message:
                'Por favor, informe o link de redirecionamento do botão!',
            },
          ]}
          initialValue={banner?.buttonLink}
        >
          <Input
            placeholder="Informe o link de redirecionamento do botão"
            value={banner?.buttonLink}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateBanner;
