import { Button, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api from '../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  category: any;
  getVideos: (path: string) => void;
}
interface IVideo {
  title: string;
  link: string;
}

const NewVideo: React.FC<IProps> = ({
  visible,
  setVisible,
  category,
  getVideos,
}) => {
  const { area } = useArea();
  const [loading, setLoading] = useState<boolean>(false);

  const handleVisible = () => {
    setVisible(false);
  };

  const handleSubmit = async ({ title, link }: IVideo) => {
    setLoading(true);
    try {
      await api.post(`/${area.endpoint}/video`, {
        title,
        link,
        categoryId: category.id,
      });
      setLoading(false);
      message.success(basicSuccessFeedback);
      getVideos(category.path);
      setVisible(false);
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      onCancel={handleVisible}
      footer={<></>}
      title="Novo Vídeo"
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Título do Vídeo"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o título do vídeo!',
            },
          ]}
        >
          <Input placeholder="Informe o título do vídeo" />
        </Form.Item>
        <Form.Item
          label="Link do Vídeo"
          name="link"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o link do vídeo!',
            },
          ]}
        >
          <Input prefix="https://youtu.be/" placeholder="id_do_video" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewVideo;
