import { Card, Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import PartnersList from './PartnersList';

const Partners: React.FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Card>
          <Title level={5}>Parceiros</Title>
          <Divider />
          <PartnersList />
        </Card>
      </Col>
    </Row>
  );
};

export default Partners;
