import { Button, DatePicker, Form, Input, message, Modal, Upload } from 'antd';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useArea } from '../../../../context/AreaContext';
import api, { apiUrl, fileUpload } from '../../../../services/api';
import { negativeFeedback } from '../../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  event: any;
  getEvents: () => void;
}

const UpdateEvent: React.FC<IProps> = ({
  visible,
  setVisible,
  event,
  getEvents,
}) => {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [newFile, setNewFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();

  useEffect(() => {
    if (event) {
      setNewFile(false);
      setFileList([
        {
          uid: '1',
          name: event?.image,
          status: 'done',
          url: event?.image,
          size: 200,
          type: '',
        },
      ]);
    }
  }, [event]);

  const handleVisible = () => {
    setVisible(false);
  };

  const handleUpload = (e: any) => {
    setFileList([e]);
    setNewFile(true);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { title, description, date } = data;
      let image;
      if (newFile) {
        image = await fileUpload(fileList[0], 'image', '/image');
      } else {
        const aux = event.image.split('/');
        image = aux[aux.length - 1];
        console.log(image);
      }
      await api.put(`/${area.endpoint}/past/event`, {
        id: event.id,
        title,
        description,
        image,
        date: date.format('YYYY-MM'),
      });
      setLoading(false);
      getEvents();
      handleVisible();
      handleRemove();
    } catch (err) {
      console.log(err);

      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Editar Evento"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Imagem do Evento"
          name="image"
          required
          rules={[
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png,  .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Nome do Evento"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do evento!',
            },
          ]}
          initialValue={event?.title}
        >
          <Input placeholder="Informe o nome do evento" value={event?.title} />
        </Form.Item>
        <Form.Item
          label="Data do Evento"
          name="date"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a data do evento!',
            },
          ]}
          initialValue={moment(event?.date, 'YYYY-MM-DD')}
        >
          <DatePicker
            picker="month"
            value={moment(event?.date, 'YYYY-MM-DD')}
          />
        </Form.Item>
        <Form.Item
          label="Descrição do Evento"
          name="description"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a descrição do evento!',
            },
          ]}
          initialValue={event?.description}
        >
          <TextArea
            placeholder="Informe a descrição do evento"
            value={event?.description}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} block type="primary" htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateEvent;
