import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import { message } from 'antd';
import { Card } from '../ListItem';
import { negativeFeedback } from '../../../utils/feedback';
import api from '../../../services/api';
import { useArea } from '../../../context/AreaContext';

export interface Item {
  id: number;
  title: string;
}

export interface ContainerState {
  cards: Item[];
}

interface IProps {
  banners: Item[];
  handleDelete: (item: any) => void;
  setBanner: (item: any) => void;
  getBanners: () => void;
  banner: any;
}

export const DragList: React.FC<IProps> = ({
  banners,
  handleDelete,
  setBanner,
  banner,
  getBanners,
}) => {
  const [cards, setCards] = useState<Array<Item>>([]);
  const { area } = useArea();

  useEffect(() => {
    if (banners) {
      setCards(banners);
    }
  }, [banners]);

  useEffect(() => {
    handleMoveCard();
  }, [cards]);

  const handleMoveCard = async () => {
    try {
      const order = cards.map(item => item.id);
      await api.post(`/${area.endpoint}/banners/order`, { order });
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [cards],
  );

  const renderCard = (card: { id: number; title: string }, index: number) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        title={card.title}
        moveCard={moveCard}
        handleDelete={handleDelete}
        setBanner={setBanner}
        banner={banner}
        getBanners={getBanners}
      />
    );
  };

  return (
    <>
      <div>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};
