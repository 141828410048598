/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Select,
  Divider,
  List,
  Button,
  Typography,
  message,
  Popconfirm,
} from 'antd';
import {
  DeleteOutlined,
  EditFilled,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Container } from './styles';
import NewProduct from '../NewProduct';
import UpdateProduct from '../UpdateProduct';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';
import api from '../../../services/api';
import { useArea } from '../../../context/AreaContext';

const { Option } = Select;
const { Paragraph } = Typography;

interface IProps {
  categories: Array<any>;
  products: any[];
  getProducts: (subCategory: string) => void;
  getHighlightProducts: () => void;
}

const ProductsList: React.FC<IProps> = ({
  categories,
  products,
  getProducts,
  getHighlightProducts,
}) => {
  const { area } = useArea();
  const [category, setCategory] = useState<number | string>();
  const [subCategory, setSubCategory] = useState<string>();
  const [subCategoryId, setSubCategoryId] = useState<string>();
  const [subCategories, setSubCategories] = useState<Array<any>>([]);
  const [productPath, setProductPath] = useState<any>();
  const [newProductVisible, setNewProductVisible] = useState<boolean>(false);
  const [updateProductVisible, setUpdateProductVisible] = useState<boolean>(
    false,
  );

  useEffect(() => {
    setCategory(0)
  }, [area]);


  useEffect(() => {
    getSubCategories();
  }, [category]);


  useEffect(() => {
    if (subCategory) getProducts(subCategory);
  }, [subCategory]);


  const handleGetProducts = () => {
    if (subCategory) getProducts(subCategory);
  };

  const getSubCategories = async () => {
    if (!category) {
      return;
    }
    try {
      const { data } = await api.get(
        `/${area.endpoint}${category}/subcategories`,
      );

      setSubCategories(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewProduct = () => {
    setNewProductVisible(!newProductVisible);
  };

  const handleUpdateProduct = (id: any) => {
    setUpdateProductVisible(!updateProductVisible);
    setProductPath(id);
  };

  const handleCategory = (id: number | string) => {
    setCategory(id);
  };

  const handleSubcategory = (path: string) => {
    setSubCategory(path);
    subCategories.map(item => {
      if (item.path === path) {
        setSubCategoryId(item.id);
      }
      return false;
    });
  };

  const handleDelete = async (id: any) => {
    try {
      await api.delete(`/${area.endpoint}/product/${id}`);
      message.success(basicSuccessFeedback);
      handleGetProducts();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleAddHighlight = async (id: any) => {
    try {
      await api.post(`/${area.endpoint}/product/spotlight`, {
        id
      });
      message.success(basicSuccessFeedback);
      getHighlightProducts();
      handleGetProducts();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleRemoveHighlight = async (id: any) => {
    try {
      await api.delete(`/${area.endpoint}/product/spotlight/${id}`);
      message.success(basicSuccessFeedback);
      getHighlightProducts();
      handleGetProducts();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const productTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  return (
    <>
      <Form style={{ display: 'flex' }} layout="vertical">
        <Form.Item label="Selecionar Categoria">
          <Select
            style={{ width: 200, marginRight: 15 }}
            onChange={handleCategory}
          >
            {categories.map(item => (
              <Option value={item.path}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Selecionar Subcategoria">
          <Select
            style={{ width: 200, marginRight: 15 }}
            onChange={handleSubcategory}
          >
            {subCategories.map(item => (
              <Option value={item.path}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label=" ">
          <Button
            disabled={!subCategory}
            onClick={handleNewProduct}
            htmlType="button"
            type="primary"
          >
            Cadastrar Produto
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Container>
        <List
          dataSource={products}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta title={productTitle(item.name)} />
              <div>
                <Button type="link">
                  {item.spotlight ? (
                    <StarFilled onClick={() => {
                      handleRemoveHighlight(item.id);
                    }}
                    />
                  ) : (
                    <StarOutlined
                      onClick={() => {
                        handleAddHighlight(item.id);
                      }}
                    />
                  )}
                </Button>
              </div>
              <div>
                <Button type="link">
                  <EditFilled
                    onClick={() => {
                      handleUpdateProduct(item.path);
                    }}
                  />
                </Button>
              </div>
              <div>
                <Popconfirm
                  title="Deseja excluir?"
                  onConfirm={() => {
                    handleDelete(item.id);
                  }}
                  okText="Excluir"
                  cancelText="Cancelar"
                >
                  <Button type="link" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>
            </List.Item>
          )}
        />
      </Container>



      {newProductVisible && (
        <NewProduct
          visible={newProductVisible}
          setVisible={setNewProductVisible}
          getProducts={handleGetProducts}
          subCategory={subCategory}
          subCategoryId={subCategoryId}
        />
      )}


      {updateProductVisible && (
        <UpdateProduct
          visible={updateProductVisible}
          setVisible={setUpdateProductVisible}
          getProducts={handleGetProducts}
          productPath={productPath}
          subCategoryId={subCategoryId}
        />
      )}

    </>
  );
};

export default ProductsList;
