import React, { useEffect, useState } from 'react';
import { Button, Divider, message } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { DragList } from '../DragList/index';
import NewBanner from '../NewBanner';
import { useArea } from '../../../context/AreaContext';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';
import api from '../../../services/api';

const BannersList: React.FC = () => {
  const [newBannerVisible, setNewBannerVisible] = useState<boolean>(false);

  const { area } = useArea();
  const [banners, setBanners] = useState<Array<any>>([]);
  const [banner, setBanner] = useState<any>();

  useEffect(() => {
    getBanners();
  }, [area]);

  const getBanners = async () => {
    try {
      const { data } = await api.get(`/${area.endpoint}/banners`);

      setBanners(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      await api.delete(`/${area.endpoint}/banner/${id}`);
      message.success(basicSuccessFeedback);
      getBanners();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleBanner = (id: any) => {
    const find = banners.find(item => item.id === id);
    if (find) {
      setBanner(find);
    }
  };

  const handleNewBanner = () => {
    setNewBannerVisible(!newBannerVisible);
  };

  return (
    <>
      <Button type="primary" onClick={handleNewBanner}>
        Cadastrar Banner
      </Button>
      <Divider />
      <Paragraph>Clique e arraste para reordenar os banners </Paragraph>
      <DragList
        banners={banners}
        handleDelete={handleDelete}
        setBanner={handleBanner}
        banner={banner}
        getBanners={getBanners}
      />

      {newBannerVisible && (
        <NewBanner
          getBanners={getBanners}
          visible={newBannerVisible}
          setVisible={setNewBannerVisible}
        />
      )}
    </>
  );
};

export default BannersList;
