import { Button, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api from '../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';

interface IVideo {
  title: string;
  link: string;
  // eslint-disable-next-line camelcase
  category_id: string;
  path: string;
  id: number;
}

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  video: IVideo;
  getVideos: (path: string) => void;
  categoryPath?: string;
}

const UpdateVideo: React.FC<IProps> = ({
  visible,
  setVisible,
  video,
  getVideos,
  categoryPath,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();

  const handleVisible = () => {
    setVisible(false);
  };

  const handleUpdate = async ({ title, link }: IVideo) => {
    setLoading(true);
    try {
      await api.put(`/${area.endpoint}/video`, {
        title,
        link,
        categoryId: video?.category_id,
        id: video?.id,
      });
      message.success(basicSuccessFeedback);
      if (categoryPath) getVideos(categoryPath);
      setVisible(false);
      setLoading(false);
    } catch (err) {
      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Editar Vídeo"
      onCancel={handleVisible}
      visible={visible}
      footer={<></>}
    >
      <Form layout="vertical" onFinish={handleUpdate}>
        <Form.Item
          label="Título do Vídeo"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o título do vídeo!',
            },
          ]}
          initialValue={video?.title}
        >
          <Input placeholder="Informe o título do vídeo" value={video?.title} />
        </Form.Item>
        <Form.Item
          label="Link do Vídeo"
          name="link"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o link do vídeo!',
            },
          ]}
          initialValue={video?.link}
        >
          <Input
            prefix="https://youtu.be/"
            placeholder="id_do_video"
            value={video?.link}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateVideo;
