import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Content, Icon } from './styles';

interface IProps {
  label: string;
  link: string;
  icon: React.ReactNode;
}

const CardButton: React.FC<IProps> = ({ label, link, icon }) => {
  return (
    <Link to={link}>
      <Container>
        <Content>
          <Icon>{icon}</Icon>
          <Paragraph>{label}</Paragraph>
        </Content>
      </Container>
    </Link>
  );
};

export default CardButton;
