import { Form, Select } from 'antd';
import React from 'react';
import { useArea } from '../../context/AreaContext';
import areas from '../../utils/areas';

import { Container } from './styles';

const { Option } = Select;

const AreaBar: React.FC = () => {
  const { area, handleArea } = useArea();

  const handleChange = (value: string) => {
    const find = areas.find(item => item.endpoint === value);
    if (find) handleArea(find);
  };

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item
          name="area"
          label="Selecione a área:"
          initialValue={area.endpoint}
        >
          <Select value={area.endpoint} onChange={handleChange}>
            {areas.map(item => (
              <Option key={item.endpoint} value={item.endpoint}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default AreaBar;
