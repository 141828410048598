import React from 'react';
import { Wrapper, AntCard, LogoBox, Logo, Content } from './styles';

import logo from '../../../assets/logo-white.png';

interface IProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<IProps> = ({ children }) => {
  return (
    <Wrapper>
      <LogoBox>
        <Logo src={logo} />
      </LogoBox>
      <Content>
        <AntCard>{children}</AntCard>
      </Content>
    </Wrapper>
  );
};

export default AuthLayout;
