import {
  AppstoreOutlined,
  InboxOutlined,
  PicLeftOutlined,
  VideoCameraOutlined,
  CommentOutlined,
  DeploymentUnitOutlined,
  UsergroupAddOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import CardButton from './CardButton';

// import { Container } from './styles';

const options = [
  {
    label: 'Banners',
    link: '/banners',
    icon: <AppstoreOutlined />,
  },
  {
    label: 'Produtos',
    link: '/produtos',
    icon: <InboxOutlined />,
  },
  {
    label: 'Artigos',
    link: '/artigos',
    icon: <PicLeftOutlined />,
  },
  {
    label: 'Vídeos',
    link: '/videos',
    icon: <VideoCameraOutlined />,
  },
  {
    label: 'Depoimentos',
    link: '/depoimentos',
    icon: <CommentOutlined />,
  },
  {
    label: 'Parceiros',
    link: '/parceiros',
    icon: <DeploymentUnitOutlined />,
  },
  {
    label: 'Vendedores',
    link: '/vendedores',
    icon: <UsergroupAddOutlined />,
  },
  {
    label: 'Eventos',
    link: '/eventos',
    icon: <PictureOutlined />,
  },
];

const Home: React.FC = () => {
  return (
    <Row gutter={[10, 20]}>
      {options.map(option => (
        <Col xs={24} sm={12} md={8} lg={6} key={option.label}>
          <CardButton
            label={option.label}
            link={option.link}
            icon={option.icon}
          />
        </Col>
      ))}
    </Row>
  );
};

export default Home;
