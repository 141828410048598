import { Button, Card, Divider, Form, Input, message, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useSession } from '../../../context/SessionContext';
import api from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

const Profile: React.FC = () => {
  const { session } = useSession();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    getUser();
  }, []);

  const getCookie = (cookieName: string) => {
    return document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  };

  const getUser = async () => {
    const id: any = getCookie('id');
    try {
      const { data } = await api.get(`/user/${id[2]}`);
      setUser(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  return (
    <Card style={{ height: '100%' }}>
      <Title level={5}>Meu Perfil</Title>
      <Divider />
      {user && (
        <Form layout="vertical">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              { required: true, message: 'Por favor, informe seu nome!' },
            ]}
            initialValue={user.name}
          >
            <Input placeholder="Informe seu nome" value={user.name} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Por favor, informe seu email!' },
            ]}
            initialValue={user.email}
          >
            <Input placeholder="Informe seu email" value={user.email} />
          </Form.Item>
          <Form.Item>
            <Button type="primary">Salvar Alterações</Button>
          </Form.Item>
        </Form>
      )}
      {!user && <Skeleton active />}
    </Card>
  );
};

export default Profile;
