import { Button, DatePicker, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useArea } from '../../../../context/AreaContext';
import api from '../../../../services/api';
import { negativeFeedback } from '../../../../utils/feedback';

const { RangePicker } = DatePicker;

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getEvents: () => void;
}

const NewEvent: React.FC<IProps> = ({ visible, setVisible, getEvents }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();
  const handleVisible = () => {
    setVisible(false);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { title, description, location, date, link } = data;
      await api.post(`/${area.endpoint}/event`, {
        title,
        description,
        location,
        link,
        date: date[0].format('YYYY-MM-DD'),
        endDate: date[1].format('YYYY-MM-DD'),
      });
      setLoading(false);
      getEvents();
      handleVisible();
    } catch (err) {
      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Novo Evento"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Nome do Evento"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do evento!',
            },
          ]}
        >
          <Input placeholder="Informe o nome do evento" />
        </Form.Item>
        <Form.Item
          label="Data do Evento"
          name="date"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a data do evento!',
            },
          ]}
        >
          <RangePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          label="Local do Evento"
          name="location"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o local do evento!',
            },
          ]}
        >
          <Input placeholder="Informe o local do evento" />
        </Form.Item>
        <Form.Item
          label="Site do Evento"
          name="link"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o site do evento!',
            },
          ]}
        >
          <Input placeholder="Informe o site do evento" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} block type="primary" htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewEvent;
