import { Row, Col, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSession } from '../../context/SessionContext';
import api from '../../services/api';
import { negativeFeedback } from '../../utils/feedback';
import NewUser from './NewUser';
import UsersList from './UsersList';

// import { Container } from './styles';

const Users: React.FC = () => {
  const [users, setUsers] = useState([]);
  const { session } = useSession();

  useEffect(() => {
    getUsers();
  }, [session.token]);

  const getUsers = async () => {
    try {
      const { data } = await api.get('/users');
      setUsers(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={24} md={8}>
        <NewUser getUsers={getUsers} />
      </Col>
      <Col span={24} md={16}>
        <UsersList users={users} getUsers={getUsers} />
      </Col>
    </Row>
  );
};

export default Users;
