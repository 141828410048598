import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Divider, List, message, Popconfirm } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import api from '../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';

interface IProps {
  users: Array<any>;
  getUsers: () => void;
}

const UsersList: React.FC<IProps> = ({ users, getUsers }) => {
  const handleDelete = async (item: any) => {
    try {
      await api.delete(`/user/${item?.id}`);
      getUsers();
      message.success(basicSuccessFeedback);
    } catch (err) {
      console.log(err.response);

      message.error(negativeFeedback);
    }
  };

  return (
    <Card>
      <Title level={5}>Usuários</Title>
      <Divider />
      <List
        dataSource={users}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta title={item.name} description={item.email} />
            <div>
              <Popconfirm
                title="Deseja excluir?"
                onConfirm={() => {
                  handleDelete(item);
                }}
                okText="Excluir"
                cancelText="Cancelar"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default UsersList;
