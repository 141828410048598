import { Card } from 'antd';
import styled from 'styled-components';

import bkg from '../../../assets/bkg-home.svg';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const LogoBox = styled.div`
  background: url(${bkg});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 250px;

  @media screen and (max-width: 700px) {
    width: 150px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AntCard = styled(Card)`
  max-width: 300px;
  min-width: 270px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
`;
