/* eslint-disable prettier/prettier */
import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import { useSession } from '../context/SessionContext';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

export interface ProtectedRouteProps extends RouteProps {
  isPrivate?: boolean;
  to?: string;
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

const RouteWrapper: React.FC<ProtectedRouteProps> = ({
  component: Component,
  isPrivate = false,
  to,
  ...rest
}) => {
  const { session } = useSession();
  const { signed } = session;
  const Layout = isPrivate ? DefaultLayout : AuthLayout;

  if (to) {
    return (
      <Redirect to={to} />
    );
  }

  if (isPrivate && !signed) {
    return (
      <Redirect to="/login" />
    );
  }

  if (!isPrivate && signed) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
