import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Divider, List, message, Popconfirm } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import UpdateTestimony from '../UpdateTestimony';
import NewTestimony from '../NewTestimony';
import { useArea } from '../../../context/AreaContext';
import api from '../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';

const TestimonialsList: React.FC = () => {
  const [newTestimonyVisible, setNewTestimonyVisible] = useState<boolean>(
    false,
  );
  const [updateTestimonyVisible, setUpdateTestimonyVisible] = useState<boolean>(
    false,
  );

  const { area } = useArea();
  const [testimonials, setTestimonials] = useState<Array<any>>([]);
  const [testimony, setTestimony] = useState<any>();

  useEffect(() => {
    getTestimonials();
  }, [area]);

  const getTestimonials = async () => {
    try {
      const { data } = await api.get(`/${area.endpoint}/references`);
      setTestimonials(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewTestimony = () => {
    setNewTestimonyVisible(!newTestimonyVisible);
  };

  const handleUpdateTestimony = (item: any) => {
    setTestimony(item);
    setUpdateTestimonyVisible(!updateTestimonyVisible);
  };

  const handleDelete = async (item: any) => {
    try {
      await api.delete(`/${area.endpoint}/reference/${item.id}`);
      message.success(basicSuccessFeedback);
      getTestimonials();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const testimonyTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  return (
    <>
      <Button type="primary" onClick={handleNewTestimony}>
        Cadastrar Depoimento
      </Button>
      <Divider />
      <List
        dataSource={testimonials}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={testimonyTitle(
                `${item.professional_name} (${item.professional_title})`,
              )}
            />
            <div>
              <Button
                type="link"
                onClick={() => {
                  handleUpdateTestimony(item);
                }}
              >
                <EditFilled />
              </Button>
            </div>
            <div>
              <Popconfirm
                title="Deseja excluir?"
                onConfirm={() => {
                  handleDelete(item);
                }}
                okText="Excluir"
                cancelText="Cancelar"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </List.Item>
        )}
      />

      {newTestimonyVisible && (
        <NewTestimony
          visible={newTestimonyVisible}
          setVisible={setNewTestimonyVisible}
          getTestimonials={getTestimonials}
        />
      )}

      {updateTestimonyVisible && (
        <UpdateTestimony
          visible={updateTestimonyVisible}
          setVisible={setUpdateTestimonyVisible}
          getTestimonials={getTestimonials}
          testimony={testimony}
        />
      )}
    </>
  );
};

export default TestimonialsList;
