import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getBanners: () => void;
}

const NewBanner: React.FC<IProps> = ({ visible, setVisible, getBanners }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const { area } = useArea();

  const handleVisible = () => {
    setVisible(false);
    handleRemove();
  };

  const handleUpload = (e: any) => {
    setFileList([e]);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { title, description, buttonLabel, buttonLink } = data;
      const image = await fileUpload(fileList[0], 'image', '/image');
      await api.post(`/${area.endpoint}/banner`, {
        title,
        description,
        buttonLabel,
        buttonLink,
        image,
      });
      setVisible(false);
      setLoading(false);
      handleRemove();
      getBanners();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Novo Banner"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Imagem do Banner (máx. 512kB)"
          name="image"
          rules={[
            {
              required: true,
              message: 'Por favor, faça o upload do arquivo!',
            },
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                if (fileList[0].size > 512 * 1024) {
                  return Promise.reject(
                    new Error('Por favor, tamanho máximo 512kB!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .gif, .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Título do Banner"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor, informe o título do banner!',
          //   },
          // ]}
          name="title"
        >
          <Input placeholder="Informe o título do banner" />
        </Form.Item>
        <Form.Item
          label="Descrição do Banner"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor, informe a descrição do banner!',
          //   },
          // ]}
          name="description"
        >
          <Input placeholder="Informe a descrição do banner" />
        </Form.Item>
        <Form.Item
          label="Texto do Botão"
          name="buttonLabel"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o texto do botão!',
            },
          ]}
        >
          <Input placeholder="Informe o texto do botão do banner" />
        </Form.Item>
        <Form.Item
          label="Link de Redirecionamento do Botão"
          name="buttonLink"
          rules={[
            {
              required: true,
              message:
                'Por favor, informe o link de redirecionamento do botão!',
            },
          ]}
        >
          <Input placeholder="Informe o link de redirecionamento do botão" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewBanner;
