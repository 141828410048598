import { Card } from 'antd';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${theme.boxShadow};
  }
`;
export const Icon = styled.span`
  font-size: 1.5em;
  color: ${theme.primary};
`;

export const Content = styled.div``;
