import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Upload, Button, message } from 'antd';
import React, { useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getArticles: (path: string) => void;
  categoryId?: string | number;
  category?: string;
}

const NewArticle: React.FC<IProps> = ({
  visible,
  setVisible,
  getArticles,
  categoryId,
  category,
}) => {
  const { area } = useArea();
  const [file, setFile] = useState<any>();
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleVisible = () => {
    setVisible(false);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const { title } = values;

      const document = await fileUpload(file, 'file', '/file');

      await api.post(`/${area.endpoint}/article`, {
        title,
        document,
        categoryId,
      });
      setVisible(false);
      if (category) getArticles(category);
      setLoading(false);
      handleRemove();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  const handleUpload = (e: any) => {
    setFileList([e]);
    setFile(e);
    return false;
  };

  const handleRemove = () => {
    setFile(null);
    setFileList([]);
  };

  return (
    <Modal
      title="Novo Artigo"
      onCancel={handleVisible}
      visible={visible}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Título do Artigo"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o título do artigo!',
            },
          ]}
        >
          <Input placeholder="Informe o título do artigo" />
        </Form.Item>
        <Form.Item
          label="Arquivo PDF (máx. 100MB)"
          name="document"
          rules={[
            {
              required: true,
              message: 'Por favor, faça o upload do arquivo!',
            },
            () => ({
              validator() {
                if (!file) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                if (file.size > 100 * 1024 * 1024) {
                  return Promise.reject(
                    new Error('Por favor, tamanho máximo 100MB!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".pdf"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit" block>
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewArticle;
