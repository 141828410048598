import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, message, Modal, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useArea } from '../../../../context/AreaContext';
import api, { fileUpload } from '../../../../services/api';
import { negativeFeedback } from '../../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getEvents: () => void;
}

const NewEvent: React.FC<IProps> = ({ visible, setVisible, getEvents }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const { area } = useArea();

  const handleVisible = () => {
    setVisible(false);
  };

  const handleUpload = (e: any) => {
    setFileList([e]);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { title, description } = data;
      const image = await fileUpload(fileList[0], 'image', '/image');
      await api.post(`/${area.endpoint}/past/event`, {
        title,
        description,
        date: data.date.format('YYYY-MM'),
        image,
      });
      setLoading(false);
      getEvents();
      handleVisible();
    } catch (err) {
      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Novo Evento"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Imagem do Evento"
          name="image"
          rules={[
            {
              required: true,
              message: 'Por favor, faça o upload do arquivo!',
            },
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Nome do Evento"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do evento!',
            },
          ]}
        >
          <Input placeholder="Informe o nome do evento" />
        </Form.Item>
        <Form.Item
          label="Data do Evento"
          name="date"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a data do evento!',
            },
          ]}
        >
          <DatePicker picker="month" format="MM-YYYY" />
        </Form.Item>
        <Form.Item
          label="Descrição do Evento"
          name="description"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a descrição do evento!',
            },
          ]}
        >
          <TextArea placeholder="Informe a descrição do evento" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} block type="primary" htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewEvent;
