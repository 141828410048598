import { Button, DatePicker, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { useArea } from '../../../../context/AreaContext';
import api from '../../../../services/api';
import { negativeFeedback } from '../../../../utils/feedback';

const { RangePicker } = DatePicker;

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  event: any;
  getEvents: () => void;
}

const UpdateEvent: React.FC<IProps> = ({
  visible,
  setVisible,
  event,
  getEvents,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();
  const handleVisible = () => {
    setVisible(false);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { title, description, location, date } = data;
      await api.put(`/${area.endpoint}/event`, {
        id: event.id,
        title,
        description,
        location,
        date: date[0].format('YYYY-MM-DD'),
        endDate: date[1].format('YYYY-MM-DD'),
      });
      setLoading(false);
      getEvents();
      handleVisible();
    } catch (err) {
      message.error(negativeFeedback);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Editar Evento"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Nome do Evento"
          name="title"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do evento!',
            },
          ]}
          initialValue={event?.title}
        >
          <Input placeholder="Informe o nome do evento" value={event?.title} />
        </Form.Item>
        <Form.Item
          label="Data do Evento"
          name="date"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a data do evento!',
            },
          ]}
          initialValue={[
            moment(event?.date, 'YYYY-MM-DD'),
            moment(event?.endDate, 'YYYY-MM-DD'),
          ]}
        >
          <RangePicker
            format="DD-MM-YYYY"
            value={[
              moment(event?.date, 'YYYY-MM-DD'),
              moment(event?.endDate, 'YYYY-MM-DD'),
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Local do Evento"
          name="location"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o local do evento!',
            },
          ]}
          initialValue={event?.location}
        >
          <Input
            placeholder="Informe o local do evento"
            value={event?.location}
          />
        </Form.Item>
        <Form.Item
          label="Site do Evento"
          name="link"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o site do evento!',
            },
          ]}
          initialValue={event?.link}
        >
          <Input placeholder="Informe o site do evento" value={event?.link} />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} block type="primary" htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateEvent;
