import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { apiUrl, fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IPartner {
  name: string;
  link: string;
  image: string;
  id: string | number;
}

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  partner: IPartner;
  getPartners: () => void;
}

const UpdatePartner: React.FC<IProps> = ({
  visible,
  setVisible,
  partner,
  getPartners,
}) => {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [newFile, setNewFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();

  useEffect(() => {
    if (partner) {
      setNewFile(false);
      setFileList([
        {
          uid: '1',
          name: partner?.image,
          status: 'done',
          url: partner?.image,
          size: 200,
          type: '',
        },
      ]);
    }
  }, [partner]);

  const handleVisible = () => {
    setVisible(false);
  };

  const handleUpload = (e: any) => {
    setFileList([e]);
    setNewFile(true);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (item: any) => {
    const { name, link } = item;

    let image;

    if (newFile) {
      image = await fileUpload(fileList[0], 'image', '/image');
    } else {
      // const aux = partner.image.split('/');
      // image = aux[aux.length - 1];
      image = partner.image;
    }

    setLoading(true);

    try {
      await api.put(`/${area.endpoint}/partner`, {
        id: partner?.id,
        name,
        link,
        image,
      });
      setVisible(false);
      setLoading(false);
      handleRemove();
      getPartners();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Editar Parceiro"
      onCancel={handleVisible}
      visible={visible}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Logo"
          name="image"
          required
          rules={[
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png,  .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Nome do Parceiro"
          name="name"
          initialValue={partner?.name}
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do parceiro!',
            },
          ]}
        >
          <Input
            placeholder="Informe o nome do parceiro"
            value={partner?.name}
          />
        </Form.Item>
        <Form.Item
          label="Site do Parceiro"
          name="link"
          initialValue={partner?.link}
          rules={[
            {
              required: true,
              message: 'Por favor, informe o site do parceiro!',
            },
          ]}
        >
          <Input
            placeholder="Informe o site do parceiro"
            value={partner?.link}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePartner;
