const areas = [
  {
    name: 'Oftalmologia',
    endpoint: 'oftalmologia',
  },
  {
    name: 'Veterinária',
    endpoint: 'veterinaria',
  },
];

export default areas;
