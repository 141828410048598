import { Button, Card, Divider, Form, Input, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useSession } from '../../../context/SessionContext';
import api from '../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../utils/feedback';
import { passwordValidation } from '../../../utils/formValidations';

const UpdatePassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { session } = useSession();

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { oldPassword, newPassword } = data;
      await api.put('/user/password', {
        id: session.id,
        oldPassword,
        newPassword,
      });
      setLoading(false);
      message.success(basicSuccessFeedback);
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Card>
      <Title level={5}>Nova Senha</Title>
      <Divider />
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Senha Atual"
          name="oldPassword"
          rules={[
            { required: true, message: 'Por favor, informe a senha atual!' },
          ]}
        >
          <Input type="password" placeholder="Informe sua senha atual" />
        </Form.Item>
        <Form.Item
          label="Nova Senha"
          name="newPassword"
          rules={[
            { required: true, message: 'Por favor, informe a nova senha!' },
            () => ({
              validator(_, value) {
                const { valid, error } = passwordValidation(value);
                if (value && !valid) {
                  return Promise.reject(new Error(error));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Informe sua nova senha" />
        </Form.Item>
        <Form.Item
          label="Confirmar Senha"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Por favor, confirme a senha!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && getFieldValue('newPassword') !== value) {
                  return Promise.reject(new Error(`As senhas não conferem!`));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirme sua nova senha" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={loading} type="primary">
            Alterar Senha
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UpdatePassword;
