import styled from 'styled-components';
import { EditFilled, MenuOutlined, DeleteOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
`;

export const IconBox = styled.div``;

export const EditIcon = styled(EditFilled)``;
export const DeleteIcon = styled(DeleteOutlined)`
  margin-right: 5px;
`;

export const MenuIcon = styled(MenuOutlined)`
  color: #a9a9a9;
  cursor: move;
`;
