import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Divider, List, message, Popconfirm } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import { useArea } from '../../../../context/AreaContext';
import api from '../../../../services/api';
import {
  basicSuccessFeedback,
  negativeFeedback,
} from '../../../../utils/feedback';
import NewEvent from '../NewEvent';
import UpdateEvent from '../UpdateEvent';

const FutureEventsList: React.FC = () => {
  const [newEventVisible, setNewEventVisible] = useState<boolean>(false);
  const [updateEventVisible, setUpdateEventVisible] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [event, setEvent] = useState<any>();
  const { area } = useArea();

  useEffect(() => {
    getEvents();
  }, [area]);

  const getEvents = async () => {
    try {
      const { data } = await api.get(`${area.endpoint}/events`);
      setEvents(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewEvent = () => {
    setNewEventVisible(!newEventVisible);
  };

  const handleUpdateEvent = (item: any) => {
    setEvent(item);
    setUpdateEventVisible(!updateEventVisible);
  };

  const handleDelete = async (id: any) => {
    try {
      await api.delete(`${area.endpoint}/event/${id}`);
      message.success(basicSuccessFeedback);
      getEvents();
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const partnerTitle = (name: string) => (
    <Paragraph style={{ marginBottom: 0 }}>{name}</Paragraph>
  );

  return (
    <>
      <Button type="primary" onClick={handleNewEvent}>
        Cadastrar Evento
      </Button>
      <Divider />
      <List
        dataSource={events}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta title={partnerTitle(item.title)} />
            <div>
              <Button
                type="link"
                onClick={() => {
                  handleUpdateEvent(item);
                }}
              >
                <EditFilled />
              </Button>
            </div>
            <div>
              <Popconfirm
                title="Deseja excluir?"
                onConfirm={() => {
                  handleDelete(item.id);
                }}
                okText="Excluir"
                cancelText="Cancelar"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </List.Item>
        )}
      />

      {newEventVisible && (
        <NewEvent
          getEvents={getEvents}
          visible={newEventVisible}
          setVisible={setNewEventVisible}
        />
      )}

      {updateEventVisible && (
        <UpdateEvent
          event={event}
          getEvents={getEvents}
          visible={updateEventVisible}
          setVisible={setUpdateEventVisible}
        />
      )}
    </>
  );
};

export default FutureEventsList;
