import React, { useRef, useState } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Button, Popconfirm } from 'antd';
import { Container, MenuIcon, IconBox, EditIcon, DeleteIcon } from './styles';
import UpdateBanner from '../UpdateBanner';

export interface CardProps {
  id: any;
  title: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  handleDelete: (item: any) => void;
  setBanner: (item: any) => void;
  getBanners: () => void;
  banner: any;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}
export const Card: React.FC<CardProps> = ({
  id,
  title,
  index,
  moveCard,
  handleDelete,
  setBanner,
  banner,
  getBanners,
}) => {
  const [updateBannerVisible, setUpdateBannerVisible] = useState<boolean>(
    false,
  );
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleEdit = () => {
    setBanner(id);
    setUpdateBannerVisible(!updateBannerVisible);
  };

  return (
    <>
      <Container ref={ref} style={{ opacity }}>
        {title}
        <IconBox>
          <Button type="link" onClick={handleEdit}>
            <EditIcon />
          </Button>
          <Popconfirm
            title="Deseja excluir?"
            onConfirm={() => {
              handleDelete(id);
            }}
            okText="Excluir"
            cancelText="Cancelar"
          >
            <Button type="link" danger>
              <DeleteIcon />
            </Button>
          </Popconfirm>
          <MenuIcon />
        </IconBox>
      </Container>
      {updateBannerVisible && (
        <UpdateBanner
          getBanners={getBanners}
          visible={updateBannerVisible}
          setVisible={setUpdateBannerVisible}
          banner={banner}
        />
      )}
    </>
  );
};
