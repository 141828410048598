import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { apiUrl, fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  testimony: any;
  getTestimonials: () => void;
}

const UpdateTestimony: React.FC<IProps> = ({
  visible,
  setVisible,
  testimony,
  getTestimonials,
}) => {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [newFile, setNewFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { area } = useArea();

  useEffect(() => {
    if (testimony) {
      setNewFile(false);
      setFileList([
        {
          uid: '1',
          name: testimony?.image,
          status: 'done',
          url: testimony?.image,
          size: 200,
          type: '',
        },
      ]);
    }
  }, [testimony]);

  const handleVisible = () => {
    setVisible(false);
  };

  const handleUpload = (e: any) => {
    setFileList([e]);
    setNewFile(true);

    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const { professionalName, professionalTitle, text } = data;

    let image;

    if (newFile) {
      image = await fileUpload(fileList[0], 'image', '/image');
    } else {
      const aux = testimony.image.split('/');
      image = aux[aux.length - 1];
    }

    try {
      await api.put(`/${area.endpoint}/reference`, {
        id: testimony?.id,
        professionalName,
        professionalTitle,
        text,
        image,
      });
      setVisible(false);
      setLoading(false);
      handleRemove();
      getTestimonials();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Editar Depoimento"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Foto do(a) Profissional"
          name="image"
          required
          rules={[
            () => ({
              validator() {
                if (fileList.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .webp"
            beforeUpload={handleUpload}
            onRemove={handleRemove}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Nome do(a) Profissional"
          name="professionalName"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do(a) profissional!',
            },
          ]}
          initialValue={testimony?.professional_name}
        >
          <Input
            placeholder="Informe o nome do(a) profissional"
            value={testimony?.professional_name}
          />
        </Form.Item>
        <Form.Item
          label="Especialidade do(a) Profissional"
          name="professionalTitle"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a especialidade do(a) profissional!',
            },
          ]}
          initialValue={testimony?.professional_title}
        >
          <Input
            placeholder="Informe a especialidade do(a) profissional"
            value={testimony?.professional_title}
          />
        </Form.Item>
        <Form.Item
          label="Depoimento do(a) Profissional"
          name="text"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o depoimento do(a) profissional!',
            },
          ]}
          initialValue={testimony?.text}
        >
          <TextArea
            placeholder="Informe o depoimento do(a) profissional"
            value={testimony?.text}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateTestimony;
