import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import cookie from 'cookie';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import api from '../services/api';

const cookieData = document.cookie;
const cookies = cookie.parse(cookieData);

const { name, token, expires } = cookies;
const date = new Date(expires);
const time = date.getUTCMilliseconds();

interface LoginData {
  userName: string;
  userToken: string;
  id: number | string;
}

interface SessionData {
  signed: boolean;
  name: string;
  token: string;
  expires: number;
  id: number | string;
}

interface SessionContextData {
  session: SessionData;
  login: ({ userName, userToken, id }: LoginData) => void;
  logout: (errorMessage?: string) => void;
}

const SessionContext = createContext<SessionContextData>(
  {} as SessionContextData,
);

const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState<SessionData>({
    signed: !!token,
    name: name || '',
    token: token || '',
    expires: token ? time : 0,
    id: 0,
  });

  const history = useHistory();

  const getCookie = (cookieName: string) => {
    return document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  };

  useEffect(() => {
    const userId = getCookie('id');
    const userToken = getCookie('name');
    const userName = getCookie('token');
    const userSession = getCookie('signed');

    if (
      userId &&
      userId.length === 3 &&
      userToken &&
      userToken.length === 3 &&
      userName &&
      userName.length === 3
    ) {
      setSession({
        name: userName[2],
        token: userToken[2],
        expires: 0,
        id: userId[2],
        signed: true,
      });
    } else if (userSession && userSession[2] === 'true' && !token) {
      logout('Sua sessão expirou!');
    }
  }, []);

  useEffect(() => {
    api.interceptors.request.use(
      (config: any) => {
        const apiToken: any = getCookie('token');
        const signed: any = getCookie('signed');

        if (apiToken && apiToken.length === 3) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${apiToken[2]}`;
        } else if (
          !apiToken &&
          session.signed &&
          signed &&
          signed[2] === 'true'
        ) {
          logout('Sua sessão expirou!');
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }, []);

  const login = useCallback(
    ({ userName, userToken, id }: LoginData) => {
      const now = new Date();
      const timeNow = now.getTime();
      now.toUTCString();
      const expiresTime = timeNow + 24 * 1000 * 3600;
      now.setTime(expiresTime);
      now.toUTCString();

      setSession({
        signed: true,
        name: userName,
        token: userToken,
        id,
        expires: expiresTime,
      });

      api.defaults.headers.Authorization = `Bearer ${token}`;
      document.cookie = `name=${userName}; expires=${expiresTime};`;
      document.cookie = `token=${userToken}; expires=${expiresTime};`;
      document.cookie = `id=${id}; expires=${expiresTime};`;
      document.cookie = `signed=true; expires=${expiresTime};`;
      history.push('/');
    },
    [history],
  );

  const logout = useCallback(
    errorMessage => {
      setSession({ signed: false, name: '', token: '', expires: 0, id: 0 });
      document.cookie = `name=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
      document.cookie = `id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
      document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
      document.cookie = `signed=false; expires=Fri, 31 Dec 9999 23:59:59 GMT`;

      history.push('/login');
      if (errorMessage) {
        message.error(errorMessage);
      }
    },
    [history],
  );

  return (
    <SessionContext.Provider
      value={{
        session,
        login,
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

function useSession(): SessionContextData {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be within an OrderProvider');
  }
  return context;
}

export { SessionProvider, useSession };
