import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, message, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import CategoriesList from '../../components/CategoriesList';
import CategoryItems from '../../components/CategoryItems';
import NewArticle from './NewArticle';
import UpdateArticle from './UpdateArticle';
import api from '../../services/api';
import { useArea } from '../../context/AreaContext';
import { basicSuccessFeedback, negativeFeedback } from '../../utils/feedback';

interface ICategory {
  name: string;
  path: string;
  type: string;
  id: number;
}

const Articles: React.FC = () => {
  const { area } = useArea();
  const [category, setCategory] = useState<ICategory>();
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState();
  const [newArticleVisible, setNewArticleVisible] = useState<boolean>(false);
  const [updateArticleVisible, setUpdateArticleVisible] = useState<boolean>(
    false,
  );

  useEffect(() => {
    getCategories();
  }, [area]);

  const getCategories = async () => {
    try {
      const { data } = await api.get(`${area.endpoint}/articles/categories`);

      setCategories(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const getArticles = async (path: string) => {
    try {
      const { data } = await api.get(`${area.endpoint}${path}/articles`);

      setArticles(data.articles);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewArticle = () => {
    setNewArticleVisible(!newArticleVisible);
  };

  const handleUpdateArticle = (item: any) => {
    setArticle(item);
    setUpdateArticleVisible(!updateArticleVisible);
  };

  const handleDeleteArticle = async (item: any) => {
    try {
      await api.delete(`/${area.endpoint}/article/${item.id}`);
      message.success(basicSuccessFeedback);
      if (category) getArticles(category.path);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} md={8}>
          <Card>
            <Title level={5}>Categorias</Title>
            <Divider />
            <CategoriesList
              type="articles"
              categories={categories}
              onUpdate={getCategories}
            />
          </Card>
        </Col>
        <Col span={24} md={16}>
          <Card>
            <Title level={5}>Artigos</Title>
            <Divider />
            <CategoryItems
              handleCategoryItems={getArticles}
              title="Artigos"
              handleNew={handleNewArticle}
              handleUpdate={handleUpdateArticle}
              handleDelete={handleDeleteArticle}
              categories={categories}
              setCategory={setCategory}
              category={category}
              items={articles}
            />
          </Card>
        </Col>
      </Row>
      {newArticleVisible && (
        <NewArticle
          visible={newArticleVisible}
          setVisible={setNewArticleVisible}
          categoryId={category?.id}
          category={category?.path}
          getArticles={getArticles}
        />
      )}

      {updateArticleVisible && (
        <UpdateArticle
          visible={updateArticleVisible}
          setVisible={setUpdateArticleVisible}
          categoryPath={category?.path}
          getArticles={getArticles}
          article={article}
        />
      )}
    </>
  );
};

export default Articles;
