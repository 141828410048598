import { Row, Col } from 'antd';
import React from 'react';
import Profile from './Profile';
import UpdatePassword from './UpdatePassword';

// import { Container } from './styles';

const Settings: React.FC = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24} md={12}>
        <Profile />
      </Col>
      <Col span={24} md={12}>
        <UpdatePassword />
      </Col>
    </Row>
  );
};

export default Settings;
