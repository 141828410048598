interface IPasswordValidation {
  valid: boolean;
  error?: string;
}

export const passwordValidation = (password: string): IPasswordValidation => {
  const lowerCaseLetters = /[a-z]/g;
  if (!password.match(lowerCaseLetters)) {
    return {
      valid: false,
      error: 'Sua senha deve conter ao menos um caractere em minúsculo.',
    };
  }
  const upperCaseLetters = /[A-Z]/g;

  if (!password.match(upperCaseLetters)) {
    return {
      valid: false,
      error: 'Sua senha deve conter ao menos um caractere em maiúsculo.',
    };
  }

  const numbers = /[0-9]/g;
  if (!password.match(numbers)) {
    return {
      valid: false,
      error: 'Sua senha deve conter ao menos um número.',
    };
  }

  if (password.length < 8) {
    return {
      valid: false,
      error: 'Sua senha deve conter ao menos 8 caractéres.',
    };
  }

  return {
    valid: true,
  };
};
