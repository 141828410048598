import { Card, Col, Divider, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import CategoriesList from '../../components/CategoriesList';
import SubcategoriesList from '../../components/SubcategoriesList';
import ProductsList from './ProductsList';
import HighlightProducts from './HighlightProducts/index';
import { useArea } from '../../context/AreaContext';
import { negativeFeedback } from '../../utils/feedback';
import api from '../../services/api';

const { Title } = Typography;

const Products: React.FC = () => {
  const { area } = useArea();
  const [products, setProducts] = useState<Array<any>>([]);
  const [highlightProducts, setHighlightProducts] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<any>([]);

  const getProducts = async (subCategory: string) => {
    try {
      const { data } = await api.get(
        `/${area.endpoint}${subCategory}/products`,
      );

      setProducts(data.products);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const getHighlightProducts = async () => {
    try {
      const { data } = await api.get(`/${area.endpoint}/products/spotlight`);
      setHighlightProducts(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  useEffect(() => {
    getCategories();
    getHighlightProducts();
    setProducts([]);
  }, [area]);

  const getCategories = async () => {
    try {
      const { data } = await api.get(`/${area.endpoint}/products/categories`);
      setCategories(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={24} md={8}>
        <Card>
          <Title level={5}>Categorias</Title>
          <Divider />
          <CategoriesList
            type="products"
            categories={categories}
            onUpdate={getCategories}
          />
        </Card>
        <br />
        <Card>
          <Title level={5}>Subcategorias</Title>
          <Divider />
          <SubcategoriesList categories={categories} />
        </Card>
      </Col>
      <Col span={24} md={16}>
        <Card>
          <Title level={5}>Produtos</Title>
          <Divider />
          <ProductsList
            getProducts={getProducts}
            getHighlightProducts={getHighlightProducts}
            products={products}
            categories={categories}
          />
        </Card>
        <br />
        <Card>
          <Title level={5}>Produtos em Destaque</Title>
          <Divider />
          <HighlightProducts
            products={highlightProducts}
            getHighlightProducts={getHighlightProducts}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Products;
