import React, { FC } from 'react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import { BrowserRouter as Router } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import GlobalStyle from './styles';
import './App.css';
import Routes from './routes';
import { AreaProvider } from './context/AreaContext';
import { SessionProvider } from './context/SessionContext';

const App: FC = () => (
  <>
    <Router>
      <SessionProvider>
        <ConfigProvider locale={ptBR}>
          <GlobalStyle />
          <AreaProvider>
            <DndProvider backend={HTML5Backend}>
              <Routes />
            </DndProvider>
          </AreaProvider>
        </ConfigProvider>
      </SessionProvider>
    </Router>
  </>
);

export default App;
