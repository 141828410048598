import { Form, Typography, Input, Button, Divider, message } from 'antd';
import React, { useState } from 'react';
import { useSession } from '../../context/SessionContext';
import api from '../../services/api';
import { negativeFeedback } from '../../utils/feedback';

const { Title } = Typography;

const SignIn: React.FC = () => {
  const { login } = useSession();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (item: any) => {
    setLoading(true);
    const { email, password } = item;
    try {
      const { data } = await api.post('/login', {
        email,
        password,
      });

      const { id, name, token } = data;

      login({ userName: name, userToken: token, id });
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        message.error('Login e/ou senha inválidos');
      } else {
        message.error(negativeFeedback);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Title level={5} style={{ textAlign: 'center' }}>
        Acessar Conta
      </Title>
      <Divider />
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Por favor, informe seu email!' }]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="Senha"
          name="password"
          rules={[{ required: true, message: 'Por favor, informe sua senha!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="link" href="/forgot-password">
            Esqueci minha senha
          </Button>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SignIn;
