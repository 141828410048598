import { Card, Col, Divider, message, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import CategoriesList from '../../components/CategoriesList';
import CategoryItems from '../../components/CategoryItems';
import { useArea } from '../../context/AreaContext';
import api from '../../services/api';
import { basicSuccessFeedback, negativeFeedback } from '../../utils/feedback';
import NewVideo from './NewVideo';
import UpdateVideo from './UpdateVideo';

interface IVideo {
  title: string;
  link: string;
  // eslint-disable-next-line camelcase
  category_id: string;
  path: string;
  id: number;
}

interface ICategory {
  name: string;
  path: string;
  type: string;
  id: number;
}

const Videos: React.FC = () => {
  const [newVideoVisible, setNewVideoVisible] = useState<boolean>(false);
  const [updateVideoVisible, setUpdateVideoVisible] = useState<boolean>(false);
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState<IVideo>({
    title: '',
    id: 0,
    path: '',
    category_id: '',
    link: '',
  });
  const [category, setCategory] = useState<ICategory>();
  const [categories, setCategories] = useState([]);
  const { area } = useArea();

  useEffect(() => {
    getCategories();
  }, [area]);

  const getCategories = async () => {
    try {
      const { data } = await api.get(`${area.endpoint}/videos/categories`);

      setCategories(data);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const getVideos = async (path: string) => {
    try {
      const { data } = await api.get(`${area.endpoint}${path}/videos`);

      setVideos(data.videos);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleNewVideo = () => {
    setNewVideoVisible(!newVideoVisible);
  };

  const handleUpdateVideo = (item: any) => {
    setUpdateVideoVisible(!updateVideoVisible);

    setVideo(item);
  };

  const handleDeleteVideo = async (item: any) => {
    try {
      await api.delete(`/${area.endpoint}/video/${item.id}`);
      message.success(basicSuccessFeedback);
      if (category?.path) getVideos(category?.path);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} md={8}>
          <Card>
            <Title level={5}>Categorias</Title>
            <Divider />
            <CategoriesList
              type="videos"
              categories={categories}
              onUpdate={getCategories}
            />
          </Card>
        </Col>
        <Col span={24} md={16}>
          <Card>
            <Title level={5}>Vídeos</Title>
            <Divider />
            <CategoryItems
              title="Vídeo"
              handleCategoryItems={getVideos}
              handleNew={handleNewVideo}
              handleUpdate={handleUpdateVideo}
              handleDelete={handleDeleteVideo}
              categories={categories}
              setCategory={setCategory}
              category={category}
              items={videos}
            />
          </Card>
        </Col>
      </Row>
      {newVideoVisible && (
        <NewVideo
          category={category}
          visible={newVideoVisible}
          setVisible={setNewVideoVisible}
          getVideos={getVideos}
        />
      )}

      {updateVideoVisible && (
        <UpdateVideo
          visible={updateVideoVisible}
          setVisible={setUpdateVideoVisible}
          video={video}
          getVideos={getVideos}
          categoryPath={category?.path}
        />
      )}
    </>
  );
};

export default Videos;
