/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Space, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useState } from 'react';
import { useArea } from '../../../context/AreaContext';
import api, { fileUpload } from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

// import { Container } from './styles';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getProducts: () => void;
  subCategory?: number | string;
  subCategoryId: any;
}

const NewProduct: React.FC<IProps> = ({
  visible,
  setVisible,
  getProducts,
  subCategory,
  subCategoryId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cover, setCover] = useState<Array<any>>([]);
  const [technicalData, setTechnicalData] = useState<Array<any>>([]);
  const [images, setImages] = useState<Array<any>>([]);
  const { area } = useArea();

  const toggleVisible = () => {
    setVisible(false);
  };

  const handleCover = (e: any) => {
    setCover([e]);
    return false;
  };

  const handleRemoveCover = () => {
    setCover([]);
  };

  const handleTechnicalData = (e: any) => {
    setTechnicalData([e]);
    return false;
  };

  const handleRemoveTechnicalData = () => {
    setTechnicalData([]);
  };

  const handleImages = (e: any) => {
    setImages([...images, e]);
    return false;
  };

  const handleRemoveImages = (e: any) => {
    setImages(images.filter(item => item.name !== e.name));
  };

  const handleRemoveAllImages = () => {
    setImages([]);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { name, description, videos, articles } = data;
      const spotlightImage = await fileUpload(cover[0], 'image', '/image');
      const imagesPaths: any = [];

      const promise = images.map(async item => {
        const path = await fileUpload(item, 'image', '/image');
        imagesPaths.push(path);
      });

      await Promise.all(promise);

      let technicalDataFile;

      if (technicalData.length > 0) {
        technicalDataFile = await fileUpload(technicalData[0], 'file', '/file');
      }

      await api.post(`/${area.endpoint}/product`, {
        name,
        description,
        subcategoryId: subCategoryId,
        spotlightImage,
        images: imagesPaths,
        technicalData: technicalDataFile,
        videos,
        articles,
      });
      setVisible(false);
      setLoading(false);
      handleRemoveCover();
      handleRemoveAllImages();
      getProducts();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      footer={null}
      visible={visible}
      title="Novo Produto"
      onCancel={toggleVisible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Nome do Produto"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do produto!',
            },
          ]}
        >
          <Input placeholder="Informe o nome do produto" />
        </Form.Item>
        <Form.Item
          label="Descrição do Produto"
          name="description"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a descrição do produto!',
            },
          ]}
        >
          <TextArea placeholder="Informe a descrição do produto" />
        </Form.Item>
        <Form.Item
          name="cover"
          label="Imagem de Capa do Produto"
          rules={[
            {
              required: true,
              message: 'Por favor, faça o upload do arquivo!',
            },
            () => ({
              validator() {
                if (cover.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                if (cover[0].size > 512 * 1024) {
                  return Promise.reject(
                    new Error('Por favor, tamanho máximo 512kB!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .webp"
            beforeUpload={handleCover}
            onRemove={handleRemoveCover}
            fileList={cover}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Imagens do Produto (máx. 10)"
          name="images"
          rules={[
            {
              required: true,
              message: 'Por favor, faça o upload do arquivo!',
            },
            () => ({
              validator() {
                if (images.length === 0) {
                  return Promise.reject(
                    new Error('Por favor, faça o upload do arquivo!'),
                  );
                }
                let error = false;
                images?.map(image => {
                  if (image.size > 512 * 1024) {
                    error = true;
                  }
                });

                if (error) {
                  return Promise.reject(
                    new Error('Por favor, tamanho máximo 512kB!'),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png, .webp"
            beforeUpload={handleImages}
            onRemove={handleRemoveImages}
            fileList={images}
            maxCount={10}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="technicalData"
          label="Ficha Técnica"
          rules={[
            () => ({
              validator() {
                if (technicalData.length === 0) {
                  return Promise.resolve();
                }
                if (technicalData[0]?.size > 100 * 1024 * 1024) {
                  return Promise.reject(
                    new Error('Por favor, tamanho máximo 100MB!'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            accept=".pdf"
            beforeUpload={handleTechnicalData}
            onRemove={handleRemoveTechnicalData}
            fileList={technicalData}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Carregar Arquivo</Button>
          </Upload>
        </Form.Item>

        <Paragraph>Vídeos</Paragraph>
        <Form.List name="videos">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'label']}
                    rules={[{ required: true, message: 'Informe o Nome' }]}
                  >
                    <Input placeholder="Título" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'link']}
                    rules={[{ required: true, message: 'Informe o Link' }]}
                  >
                    <Input placeholder="Link" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Vídeo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Paragraph>Artigos</Paragraph>
        <Form.List name="articles">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'label']}
                    rules={[{ required: true, message: 'Informe o Nome' }]}
                  >
                    <Input placeholder="Título" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'link']}
                    rules={[{ required: true, message: 'Informe o Link' }]}
                  >
                    <Input placeholder="Link" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Artigo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewProduct;
