import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';
import Articles from '../pages/Articles';
import Videos from '../pages/Videos';
import Testimonials from '../pages/Testimonials';
import Partners from '../pages/Partners';
import Sellers from '../pages/Sellers';
import Events from '../pages/Events';
import Banners from '../pages/Banners';
import Users from '../pages/Users';
import Settings from '../pages/Settings';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={SignIn} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:token" component={ResetPassword} />
      <Route exact path="/" isPrivate component={Home} />
      <Route exact path="/produtos" isPrivate component={Products} />
      <Route exact path="/artigos" isPrivate component={Articles} />
      <Route exact path="/videos" isPrivate component={Videos} />
      <Route exact path="/depoimentos" isPrivate component={Testimonials} />
      <Route exact path="/parceiros" isPrivate component={Partners} />
      <Route exact path="/vendedores" isPrivate component={Sellers} />
      <Route exact path="/eventos" isPrivate component={Events} />
      <Route exact path="/banners" isPrivate component={Banners} />
      <Route exact path="/usuarios" isPrivate component={Users} />
      <Route exact path="/configuracoes" isPrivate component={Settings} />
      <Route path="/" to="/" component={Home} />
    </Switch>
  );
};

export default Routes;
