import { Button, Form, Input, message, Modal, Select, Tag } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import { negativeFeedback } from '../../../utils/feedback';

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  getSellers: () => void;
}

const NewSeller: React.FC<IProps> = ({ visible, setVisible, getSellers }) => {
  const [states, setStates] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sellerstates, setSellerStates] = useState<Array<any>>([]);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const { data } = await api.get('/states');
      setStates(data.states);
    } catch (err) {
      message.error(negativeFeedback);
    }
  };

  const handleSelectState = (id: any) => {
    const exist = sellerstates.find(item => Number(item.id) === Number(id));
    if (exist) {
      return;
    }
    const find = states.find(item => Number(item.id) === Number(id));
    setSellerStates([...sellerstates, find]);
  };

  const handleRemoveState = (id: any) => {
    const filtered = sellerstates.filter(
      item => Number(item.id) !== Number(id),
    );
    setSellerStates(filtered);
  };

  const handleVisible = () => {
    setVisible(false);
  };

  const handleSubmit = async (items: any) => {
    setLoading(true);
    try {
      const { name, email, phone } = items;
      const { data } = await api.post(`/seller`, {
        name,
        email,
        phone,
      });

      const { id } = data;
      const ids: any[] = [];
      sellerstates.map(item => ids.push(item.id));
      await api.post(`/seller/states`, {
        id,
        states: ids,
      });

      setVisible(false);
      setLoading(false);
      getSellers();
    } catch (err) {
      setLoading(false);
      message.error(negativeFeedback);
    }
  };

  return (
    <Modal
      title="Novo Vendedor"
      onCancel={handleVisible}
      footer={null}
      visible={visible}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Nome do Vendedor"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o nome do vendedor!',
            },
          ]}
        >
          <Input placeholder="Informe o nome do vendedor" />
        </Form.Item>
        <Form.Item
          label="Email do Vendedor"
          name="email"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o email do vendedor!',
            },
          ]}
        >
          <Input type="email" placeholder="Informe o email do vendedor" />
        </Form.Item>
        <Form.Item
          label="Whatsapp do Vendedor"
          name="phone"
          rules={[
            {
              required: true,
              message: 'Por favor, informe o whatsapp do vendedor!',
            },
          ]}
        >
          <InputMask
            mask="+55 99 99999-9999"
            type="tel"
            placeholder="Informe o whatsapp do vendedor"
            className="input-mask"
          />
        </Form.Item>
        <Form.Item
          label="Estados onde Atende"
          name="states"
          required
          rules={[
            () => ({
              validator() {
                if (sellerstates.length === 0) {
                  return Promise.reject(
                    new Error(
                      'Por favor, adicione ao menos um estado de atendimento!',
                    ),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select onChange={handleSelectState}>
            {states.map(item => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
          <br />
          <br />
          <div>
            {sellerstates.map(item => (
              <Tag
                closable
                color="blue"
                onClose={() => {
                  handleRemoveState(item.id);
                }}
                key={item.id}
              >
                {item.name}
              </Tag>
            ))}
          </div>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" block htmlType="submit">
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewSeller;
