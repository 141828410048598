import React, { createContext, useContext, useState, useCallback } from 'react';
import areas from '../utils/areas';

interface AreaData {
  name: string;
  endpoint: string;
}

interface AreaContextData {
  area: AreaData;
  handleArea: ({ name, endpoint }: AreaData) => void;
}

const AreaContext = createContext<AreaContextData>({} as AreaContextData);

const AreaProvider: React.FC = ({ children }) => {
  const [area, setArea] = useState<AreaData>({
    name: areas[0].name,
    endpoint: areas[0].endpoint,
  });

  const handleArea = useCallback(({ name, endpoint }) => {
    setArea({ name, endpoint });
  }, []);

  return (
    <AreaContext.Provider
      value={{
        area,
        handleArea,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};

function useArea(): AreaContextData {
  const context = useContext(AreaContext);

  if (!context) {
    throw new Error('useArea must be within an OrderProvider');
  }
  return context;
}

export { AreaProvider, useArea };
